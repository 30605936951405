<template>
    <!-- Logo -->
    <div class="wordmark_container">
        <router-link to="/">
            <img src="/images/logotype_davidmoreau.svg" alt="David Moreau" class="wordmark">
        </router-link>
    </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useModularScale, useSemanticColors } from '@/composables/useStyleVariables';
const { scale } = useModularScale();
const { bgColors } = useSemanticColors();

const state = inject("state");

const markHt = computed(() => {
  if (state.value.isDesktop) return scale.value.text2 + "px";
  if (state.value.isLaptop) return scale.value.text0 + "px";
  else return scale.value.text0 + "px"
});

</script>

<style scoped>
/* LOGO STYLING */
.wordmark_container {
  height: fit-content;
  justify-self: end;
  background-color: v-bind('bgColors.lightTranslucent');
  z-index: 10001;
}

.wordmark {
  height: v-bind('markHt');
}
</style>