<!-- App.vue -->
<template>
  <div id="app" :style="appStyle">
    <div :style="mainContainerStyle">

      <div ref="navMenu" :style="navMenuStyle">
        <NavMenu :links="sectionLinks" @first-link-baseline="handleFirstLinkBaseline"/>
      </div>

      <div ref="wordMark" :style="wordmarkStyle">
        <WordMark></WordMark>
      </div>

      <div :style="contentContainerStyle">
        <router-view v-slot="{ Component }" :key="$route.name">
          <component :is="Component" @update-extended="updateExtended" @toggle-scrollbars="toggleBodyOverflow"/>
        </router-view>
      </div>
    </div>

    <footer ref="footer" :style="footerStyle">
      <p>&copy; {{ currentYear }} David Moreau. All rights reserved.</p>
    </footer>

  </div>
</template>

<script setup>
import { ref, provide, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useBreakpoints } from './composables/useBreakpoints';
import { useModularScale, useSemanticColors } from './composables/useStyleVariables';
import WordMark from "./components/interactive/WordMark.vue";
import NavMenu from "./components/navigation/NavMenu.vue";
import json from "@/assets/json/portfolio.json";
import PortfolioData from "./assets/js/PortfolioData.js";

// Route

// State
const portfolioData = ref(null);
const marketObject = ref(null);

const sectionLinks = ref([{ path: "/", text: "Home" }]);

const extended = ref(false);
const overflowHidden = ref(false);
const textBaseline = ref(70);
const wordMarkHeight = ref(100);

// Refs
const navMenu = ref(null);
const wordMark = ref(null);
const footer = ref(null);

// Router Stuff - store current route and queries
const route = useRoute();
const router = useRouter();
const queryParams = ref({});
const marketId = ref(null);

const updateQueryParams = () => {
      const params = new URLSearchParams(window.location.search)
      const newParams = {}
      for (const [key, value] of params) {
        newParams[key] = value
      }
      queryParams.value = newParams
}

// Styling - Sizes / Colors / Breakpoints
const { isMobile, isTablet, isLaptop, isDesktop } = useBreakpoints();
const { scale, setModularBaseSize, setModularMultiplier } = useModularScale();
const { bgColors, textColors, buttonColors } = useSemanticColors();

// Provisions
const state = computed(() => ({
  isMobile: isMobile.value,
  isTablet: isTablet.value,
  isLaptop: isLaptop.value, 
  isDesktop: isDesktop.value
}));

const semanticSizes = {
  navMenuWidth: ref(24)
};

// for adjusting for mobile
const viewportHeight = ref(window.innerHeight);
const updateViewportHeight = () => {
  // Do this to account for nav bars in mobile browswers.
  console.log("App Updating Viewport Height to", window.innerHeight);
    viewportHeight.value = window.innerHeight;
};

// mobile spacer height
const mobileSpacerHeight = computed(() => {
  if (state.value.isMobile || state.value.isTablet) {
    return 100;
  } else {
    return 0;
  }
});

provide('state', state);
provide('semanticSizes', semanticSizes);
provide('viewportHeight', viewportHeight);
provide('mobileSpacerHeight', mobileSpacerHeight);

// Computed
const currentYear = computed(() => new Date().getFullYear());

// ### STYLES ###
const styleMargins = computed(() => {

  let topMargin = scale.value.text0 + "px";
  if (state.value.isDesktop) topMargin = scale.value.text2;
  if (state.value.isLaptop) topMargin = scale.value.text0;
  if (state.value.isTablet) topMargin = scale.value.text0000;
  if (state.value.isMobile) topMargin = scale.value.text0000;

  let sideMargin = scale.value.text2;
  if (state.value.isDesktop) sideMargin = scale.value.text2;
  if (state.value.value) sideMargin = scale.value.text0;
  if (state.value.value) sideMargin = scale.value.text000;
  if (state.value.value) sideMargin = scale.value.text000;

  // console.log(`Top Margin: ${topMargin} | Side Margin: ${sideMargin}`)
  return {
    topMargin: topMargin,
    sideMargin: sideMargin
  }
});

const appStyle = computed(() => {

  console.log("computing app style");
  
  const baseStyle = {
    width: '100%',
    // minHeight: '100vh',
    height: overflowHidden.value ? '`${viewportHeight.value}px`' : 'unset',
    minHeight: overflowHidden.value ? `${viewportHeight.value}px` : '100vh',
    display: 'flex',
    flexDirection: 'column'
  }

  return baseStyle;

});

const mainContainerStyle = computed(() => {

  let rowTemplate = `${styleMargins.value.topMargin}px ${textBaseline.value}px 1fr`;
  const colTemplate = `${styleMargins.value.sideMargin}px ${semanticSizes.navMenuWidth.value}px 1fr ${styleMargins.value.sideMargin}px`

  const baseStyle = {
    display: 'grid',
    flex: '1',
    gridTemplateRows: rowTemplate,
    gridTemplateColumns: colTemplate,
    width: '100%',
    minHeight: '100vh',
    overflow: 'visible'
  }

  return baseStyle;
}
);

const navMenuStyle = computed(() => {

  const baseStyle = {
  // gridRow: ' 2 / 3',
  // gridColumn: '2 / -1',
  position: 'fixed',
  top: `${styleMargins.value.topMargin}px`,
  left: styleMargins.value.sideMargin + "px",
  width: 'fit-content',
  height: 'fit-content',
  zIndex: 4999,
  }

  return baseStyle

});

const wordmarkStyle = computed(() => {

  let stickyTop;
  if (state.value.isMobile || state.value.isTablet) stickyTop =  `${styleMargins.value.topMargin}px`;
  else stickyTop = `${styleMargins.value.topMargin + textBaseline.value - wordMarkHeight.value}px`

  const baseStyle = {
  // gridRow: '2 / 3',
  // gridColumn: ' 2 / -2',
  position: 'fixed',
  top: stickyTop,
  right: styleMargins.value.sideMargin + "px",
  justifySelf: 'end',
  alignSelf: state.value.isMobile ? 'start' : 'end',
  width: 'fit-content',
  height: 'fit-content',
  zIndex: 4999,
}

return baseStyle;
});

const contentContainerStyle = computed(() => {
  let gridRow;
  let gridColumn;

  // console.log("Container Style Extended", extended.value)

  if (state.value.isTablet || state.value.isMobile) {
    if (extended.value) {
      gridRow = '1 / -1';
      gridColumn = '1 / -1';
    } else {
      gridRow = '3 / -1'
      gridColumn = '2 / -2';
    }
  }

  if (isDesktop.value || isLaptop.value) {
    if (extended.value) {
      gridRow = '1 / -1';
      gridColumn = '1 / -1';
  } else {
      gridRow = '3 / -1';
      gridColumn = '3 / -2';
  }

  }

  const baseStyle = {
    overflow: 'visible',
    gridRow: gridRow,
    gridColumn: gridColumn 
  };

  return baseStyle;

});

const footerStyle = computed(() => ({
  height: `${scale.value.text2}px`,
  position: 'sticky',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 4998,
}));

// ### INITILIZATION ###
const initializeApp = async (retries = 3) => {
  try {
    console.log("initializeApp state", state.value);
    portfolioData.value = new PortfolioData(json);
    marketId.value = queryParams.value["mk"];
    console.log("initializeApp marketId", marketId.value);

    if (marketId.value) {
      marketObject.value = portfolioData.value.getMarketObj(marketId.value);
      if (!marketObject.value) {
        throw new Error(`Can't find Market Object for ${marketId.value}`);
      }
    }
    if (!marketObject.value) {
      marketObject.value = portfolioData.value.getDefaultMarketObj();
    }

    createLinkArray();
  } catch (error) {
    if (retries > 0) {
      console.error(`Error initializing app, retrying... (${retries} attempts left)`);
      await new Promise(resolve => setTimeout(resolve, 1000));
      return initializeApp(retries - 1);
    } else {
      console.error('Failed to initialize app after multiple attempts:', error);
      router.replace({ name: 'NotFound' });
    }
  }
};

const createLinkArray = () => {
  const linkArray = [];
  marketObject.value["sections"].forEach(section => {
    const sectionObj = portfolioData.value.getSectionObj(section);
    if (sectionObj) {
      linkArray.push({ path: sectionObj["routerPath"], text: sectionObj["displayName"] });
    }
  });
  sectionLinks.value = linkArray;
};

// ### HANDLERS & UPDATERS ###
const handleFirstLinkBaseline = (baseline) => {
  textBaseline.value = baseline;
};

const handleResize = () => {
  updateDimensions();
  updateModularSizes();
  updateViewportHeight();
};

const updateModularSizes = () => {
  let baseSize;
  let mult;
  if (state.value.isMobile) {
    baseSize = 16;
    mult = 1.25;
  }
  else if (state.value.isTablet) {
    baseSize = 18;
    mult = 1.333;
  }
  else if (state.value.isLaptop) {
    baseSize = 18;
    mult = 1.414;
  }
  else if (state.value.isDesktop) {
    baseSize = 20;
    mult = 1.414;
  }

  setModularBaseSize(baseSize);
  setModularMultiplier(mult);

};

const updateExtended = (value) => {
    extended.value = value;
};

const updateDimensions = () => {
  if (navMenu.value) {
    console.log("App Setting NavMenuWidth to ", navMenu.value.offsetWidth)
    semanticSizes.navMenuWidth.value = navMenu.value.offsetWidth;
  }
  if (wordMark.value) {
    wordMarkHeight.value = wordMark.value.offsetHeight;
  }
};

const toggleBodyOverflow = (hide) => {
  console.log("App toggleBodyOverflow", hide);
  overflowHidden.value = hide; // track state
  const overflowValue = hide ? 'hidden' : 'auto';
  document.documentElement.style.overflowY = overflowValue;
  document.body.style.overflowY = overflowValue;

  document.ontouchmove = (e) => {
    if (overflowHidden.value) {
      e.preventDefault();
    }
  };

};

watch(route, () => {
  extended.value = route.meta.extended ?? false;
  console.log("Route changed. route. Current path:", route.fullPath);

}, {immediate: true});

watch(() => route.fullPath, () => {
      console.log("Route changed. Current path:", route.fullPath);
      updateQueryParams();
    }, { immediate: true });

// ### LIFECYCLE HOOKS ###
onMounted(async () => {
  await initializeApp();
  // applyBodyStyles();
  updateDimensions();
  updateModularSizes();
  updateViewportHeight();
  window.addEventListener('resize', handleResize);
  updateQueryParams();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});



</script>

<style>
/* Non-scoped (global) styles here */

@import url("https://use.typekit.net/mia7dtk.css");

* {
    font-family: "futura-pt", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
  font-size: v-bind('scale.text0 + "px"')px;
  height: auto;
  min-height: 100%;
  overflow-y: auto;
}

ul {
    list-style-type: none;
    color: inherit;
}

li {
    text-decoration: none;
    color: inherit;
}

a {
    font-size: v-bind('scale.text0 + "px"');
    font-family: "franklin-gothic-condensed", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    display: inline;
    text-decoration: none;
    text-align: left;
    color: v-bind('textColors.accent');
    cursor: pointer;
    pointer-events: auto;
}

a:hover {
    color: v-bind('buttonColors.hover');
}

h1, h2, h3, h4, h5, h6 {
    font-family: "futura-pt", sans-serif;
    font-weight: 300;
    font-style: normal;
}

p, li {
    font-size: v-bind('scale.text0 + "px"');
    font-family: "franklin-gothic-condensed", 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

h1 {
    font-size: v-bind('scale.text6 + "px"');
}

h2 {
    font-size: v-bind('scale.text5 + "px"');

}

h3 {
    font-size: v-bind('scale.text4 + "px"');
    line-height: v-bind('scale.text4 + "px"');
}

h4{
    font-size: v-bind('scale.text3 + "px"');

}

h5 {
    font-size: v-bind('scale.text2 + "px"');

}

h6 {
    font-size: v-bind('scale.text1 + "px"');

}

.mobile-spacer {
  width: 100%;
  height: v-bind('mobileSpacerHeight + "px"');
}

</style>

<style scoped>

/* 
#app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
*/

.content {
  min-height: 100%;
}

footer {
  padding: v-bind('scale.text000 + "px"');
  text-align: start;
  background-color: v-bind('bgColors.lightTranslucent');
  color: v-bind('textColors.bgLightFaint');
}

footer p {
  font-size: v-bind('scale.text00 + "px"');
}

</style>
