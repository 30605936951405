<template>
  <div :style="navTopRowStyle">

    <!-- Desktop Links -->
    <div v-if="!showMobile" class="nav-menu-desktop">
      <ul class="nav-links-desktop">
        <li v-for="(link, index) in props.links" :key="index">
          <router-link
          v-if="link && link.path" 
          :to="link.path" 
          :ref="index === 0 ? setFirstLinkRef : undefined" 
          class="baseline-measure"
          >
            {{ link.text }}
            <span :ref="index === 0 ? 'baselineHelper' : undefined" class="baseline-helper"></span>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Mobile Links -->
    <div v-else class="nav-menu-mobile">
      <div class="city-in-germany">
        <NavHamburger @click="toggleMenu" class="rhine"></NavHamburger>
      </div>
    </div>

    <!-- Modal -->
    <transition name="slide-up">
      <div v-if="mobileMenuOpen" class="modal" :class="{'modal-opaque': state.isMobile}">
        <div class="modal-content">
          <ul class="nav-links-mobile">
            <li v-for="(link, index) in props.links" :key="index">
              <router-link :to="link.path" @click="closeMobileMenu">{{ link.text }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </transition>

  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, computed, inject, nextTick } from 'vue';
import { useModularScale, useSemanticColors } from '@/composables/useStyleVariables';
import NavHamburger from "../interactive/NavHamburger.vue";

const props = defineProps({
  links: {
    type: Array,
    default: () => [
      { path: "/", text: "Home" }
    ],
    validator: (value) => value.every(link => typeof link.path === 'string' && typeof link.text === 'string')
  },
});

const emit = defineEmits(['mobile-state', 'mobile-menu-state', 'first-link-baseline']);
const state = inject("state");

// Mobile Handler
const mobileMenuOpen = ref(false);
const showMobile = computed(() => {
  if (state.value.isMobile || state.value.isTablet) return true;
  return false;
});

// Styles
const { scale } = useModularScale();
const { bgColors, buttonColors, textColors } = useSemanticColors();

// font size
const fontSize = computed(() => {
  let fontSize = scale.value.text0 + "px";
  if (state.value.isDesktop) fontSize = scale.value.text3 + "px";
  if (state.value.isLaptop) fontSize = scale.value.text1 + "px";
  if (state.value.isTablet) fontSize = scale.value.text2 + "px";
  if (state.value.isMobile) fontSize = scale.value.text3 + "px";
  return fontSize;
});

const gapSize = computed(() => {
  let gapSize = scale.value.text0 + "px";
  if (state.value.isDesktop) gapSize = scale.value.text3 + "px";
  return gapSize;
});

// Computed style for nav-top-row
const navTopRowPadTop = computed(() => {
  if (state.value.isTablet || state.value.isMobile) return '0px';
  else return scale.value.text00 + "px";
});
const navTopRowPadSide = computed(() => {
  if (state.value.isTablet) return scale.value.text000 + "px";
  else if (state.value.isMobile) return '0px';
  else return scale.value.text00 + "px";
});
const navTopRowStyle = computed(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  pointerEvents: 'none',
  backgroundColor: bgColors.value.lightTranslucent,
  width: 'fit-content',
  paddingTop: navTopRowPadTop.value,
  paddingRight: navTopRowPadSide.value,
  paddingLeft: navTopRowPadSide.value,
  margin: '0',
}));

// Mobile Menu Handlers
function toggleMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
  emit('mobile-menu-state', mobileMenuOpen.value);
}

// baseline emit
const firstLinkRef = ref(null);
const baselineHelperRef = ref(null);
const setFirstLinkRef = (el) => {
  firstLinkRef.value = el;
};

// Lifecycle Hooks
onMounted(() => {
  emit('mobile-menu-state', mobileMenuOpen.value);

    // emit height of first link baseline
    nextTick(() => {
    const linkElement = firstLinkRef.value;
    const helperElement = baselineHelperRef.value;

    if (linkElement && helperElement) {
      const linkRect = linkElement.getBoundingClientRect();
      const helperRect = helperElement.getBoundingClientRect();

      const baseline = helperRect.bottom - linkRect.top;
      emit('first-link-baseline', baseline);
    }
  });

});

function closeMobileMenu() {
  mobileMenuOpen.value = false;
  emit('mobile-menu-state', false);
}
</script>

<style scoped>
/* DESKTOP */
.nav-links-desktop {
  display: flex;
  flex-direction: column;
  gap: v-bind('gapSize');
  background-color: transparent;
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: nowrap;
}

.nav-links-desktop li {
  display: inline-block;
}

.nav-links-desktop a {
  pointer-events: auto;
  font-size: v-bind('fontSize');
  line-height: v-bind('fontSize');
  text-decoration: none;
  display: block;
  color: v-bind('textColors.bgLight');
  letter-spacing: .25rem;
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
}

.nav-links-desktop a.router-link-active {
  color: v-bind('buttonColors.selected');
}

.nav-links-desktop a:hover {
  color: v-bind('buttonColors.hover');
}

/* MOBILE */
.nav-menu-mobile {
  padding-top: 2px;
}

.city-in-germany {
  width: fit-content;
  height: fit-content;
}

.rhine {
  cursor: pointer;
  pointer-events: auto;
  height: 1.5rem;
  width: 1.5rem;
}

.german-menu-close-button {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  z-index: 4999;
}


.nav-links-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: v-bind('gapSize');
  /* background-color: transparent;
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: nowrap; */
}


.nav-links-mobile a {
  font-size: v-bind('fontSize');
  color: v-bind('textColors.bgLight');
}

.nav-links-mobile a.router-link-active {
  color: v-bind('buttonColors.selected');
}

.nav-links-mobile a:hover {
  color: v-bind('buttonColors.hover');
}

/* baseline measuring stick */
.baseline-measure {
  position: relative;
  display: inline-block;
}

.baseline-helper {
  display: inline-block;
  width: 0;
  height: 0;
}

.baseline-helper::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: baseline;
}

/* Modal Styles */
.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: v-bind('bgColors.lightTranslucent');
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
  pointer-events: none;
}

.modal-opaque {
  background-color: v-bind('bgColors.light');
}

.modal-content {
  background-color: white;
  padding: v-bind('scale.text0 + "px"');
  border-radius: v-bind('scale.text0000 + "px"');
  text-align: center;
  pointer-events: auto;
}

.modal-content-mobile {

  width: 100%;
  height: 100%;

}

/* Transition Styles */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
}
</style>