class PortfolioData {

    constructor(data) {
        this.portObjPath = "../assets/json/portfolio.json";
        this.portfolioData = data;
    }

    // #### Object Getters ####
    getObjByPath(path) {
        // console.log(`Getting Object at ${path}`);
        const keys = path.split("/");
        let current = this.portfolioData;

        for (const key of keys) {
            // console.log(key);
            if (current[key] === undefined) {
                return undefined; // Return undefined if the path is invalid
            }
            current = current[key];       
        }

        return current
    }

    getMarketObj(marketId) {
        // console.log(`Getting Market Object for ${marketId}`);
        const marketObj = this.portfolioData.markets[marketId];

        if (!marketObj) {return null;}
        return marketObj;
    }

    getDefaultMarketObj() {
        // console.log("Getting Default Market Object");
        let marketObj = null;
        // look for object marked default
        Object.values(this.portfolioData.markets).forEach(market => {
            if (market["default"] == true) marketObj = market;
        });

        // if none marked default return the first one
        if (!marketObj) marketObj = this.portfolioData.markets[0];

        return marketObj;
    }

    /**
     * 
     * @param {string} sectionId - Id of section object to get.
     * @returns {Object} - A promise that resolves to an objects representing the section. Returns Default Section Obj if section object not found.
     */
    getSectionObj(sectionId) {
        // console.log(`Getting Section Object for ${sectionId}`);
        const sectObj = this.portfolioData.sections[sectionId];

        if (!sectObj) {return this.portfolioData.sections["default"];}
        return sectObj;
    }

    getDefaultSectionObj() {
        // console.log("Getting Default Section Object");
        let sectionObj = null;
        // look for object marked default
        Object.values(this.portfolioData.sections).forEach(section => {
            if (section["default"] == true) sectionObj = section;
        });

        // if none marked default return the first one
        if (!sectionObj) sectionObj = this.portfolioData.markets[0];

        return sectionObj;

    }

    /**
     * @param {Array<string>} sectIdList - The list of section IDs.
     * @returns {Promise<Array<Object>>} - A promise that resolves to an array of section objects.
     */
    getSectionObjectsForNav(sectIdList) {
        const sObjList = sectIdList
        .map(sect => this.portfolioData.sections[sect])
        .filter(section => section !== undefined)
        .filter(section => section.includeInNav == true);

        return sObjList;
    }

    getProjectObj(projId) {
        const projObj = this.portfolioData.projects[projId];

        if (!projObj) {return null;}
        return projObj;
    }

     getProjectObjs(projIdList) {
        const pObjList = projIdList
        .map(proj => this.portfolioData.projects[proj])
        .filter(proj => proj !== undefined);

        return pObjList;
    }
}

export default PortfolioData ;