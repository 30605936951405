<template>
    <svg class="hamb-svg" 
        width=15.52
        height=17.85
        viewBox="0 0 17.85 15.52"
        >
        <g>
        <line class="ham-line" x1="1.5" y1="1.5" x2="16.35" y2="1.5"/>
        <line class="ham-line" x1="1.5" y1="7.76" x2="16.35" y2="7.76"/>
        <line class="ham-line" x1="1.5" y1="14.02" x2="16.35" y2="14.02"/>
        </g>
    </svg>
</template>

<script setup>
import { useSemanticColors } from '@/composables/useStyleVariables';

const { buttonColors } = useSemanticColors();
</script>

<style scoped>
.hamb-svg {
    width: auto;
    cursor: pointer;
}

.ham-line {
    fill: none;
    stroke: v-bind('buttonColors.neutralDark');
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.hamb-svg:hover .ham-line {
    stroke: v-bind('buttonColors.hover');
}
</style>