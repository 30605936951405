import { ref, onMounted, onUnmounted, computed } from 'vue'
import breakpoints from '@/assets/json/portfolioBreakpoints.json'

export function useBreakpoints() {
  const windowWidth = ref(window.innerWidth)

  const onResize = () => {
    windowWidth.value = window.innerWidth
  }

  onMounted(() => window.addEventListener('resize', onResize))
  onUnmounted(() => window.removeEventListener('resize', onResize))

  // console.log("useBreakpoints Window Size", window.innerWidth);
  const isMobile = computed(() => windowWidth.value < breakpoints.mobile);
  const isTablet = computed(() => windowWidth.value >= breakpoints.mobile && windowWidth.value < breakpoints.tablet);
  const isLaptop = computed(() => windowWidth.value >= breakpoints.tablet && windowWidth.value < breakpoints.laptop);
  const isDesktop = computed(() => windowWidth.value >= breakpoints.laptop);

  const fontSize = computed(() => {
    if (isDesktop.value) {return 20}
    else if (isLaptop.value) { return 18}
    else if (isTablet.value) { return 18}
    else if (isMobile.value) { return 16}
    else return 20
  });

  const fontMult = computed(() => {
    if (isDesktop.value) {return 1.414}
    else if (isLaptop.value) { return 1.414}
    else if (isTablet.value) { return 1.333}
    else if (isMobile.value) { return 1.250}
    else return 1.414
  });


  return {
    isMobile: isMobile,
    isTablet: isTablet,
    isLaptop: isLaptop,
    isDesktop: isDesktop,
    fontSize: fontSize,
    fontMult: fontMult
  }
}